<template>
  <div>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>User Profile</h1>
            <!-- {{ this.$auth.user() }} -->
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a>Home</a>
              </li>
              <li class="breadcrumb-item active">Profile</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <!-- Profile Image -->
            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img
                    class="profile-user-img img-fluid img-circle"
                    src="./../assets/img/default-user.png"
                    alt="User profile picture"
                  />
                </div>

                <h3 class="profile-username text-center">{{ this.$auth.user().first_name }} {{ this.$auth.user().last_name }}</h3>

                <p class="text-muted text-center">{{ this.$auth.user().position }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      v-bind:class="{ active: selectedTab === 'INFO' }"
                      v-on:click="selectedTab = 'INFO'"
                      >Info</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-bind:class="{ active: selectedTab === 'SETTINGS' }"
                    v-on:click="selectedTab = 'SETTINGS'"
                  >
                    <a class="nav-link">Settings</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div class="tab-pane" v-bind:class="{ active: selectedTab === 'INFO' }" >
                    <form class="p-2">
                      <div class="form-row">
                        <div class="form-group col-sm-12 col-md-12">
                          <label>Benutzername</label>
                          <input type="text" v-model="form.username" class="form-control form-control-sm" disabled />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-12 col-md-6">
                          <label>Vorname</label>
                          <input type="text" v-model="form.first_name" class="form-control form-control-sm" />
                        </div>
                        <div class="form-group col-sm-12 col-md-6">
                          <label>Nachname</label>
                          <input type="text" v-model="form.last_name" class="form-control form-control-sm" />
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 col-md-6">
                          <label class="control-label">Passwort ändern?</label>
                            <select v-model="changePassword" class="form-control form-control-sm">
                                <option v-bind:value=true>Ja</option>
                                <option v-bind:value=false>Nein</option>
                            </select>
                        </div>
                      </div>  

                      <div class="form-row" v-if="changePassword">
                        <div class="form-group col-sm-12 col-md-6">
                          <label class="control-label">Passwort</label>
                          <input v-model="form.password" type="password" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('password')}" tabindex="5"/>
                          <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group col-sm-12 col-md-6">
                          <label class="control-label">Passwort Wiederholen</label>
                          <input v-model="form.password_confirmation" type="password" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('password_confirmation')}" tabindex="6" />
                          <has-error :form="form" field="password_confirmation"></has-error>
                        </div>
                      </div>

                      <hr />

                      <div>
                        <button type="button" class="btn btn-block btn-primary w-auto float-right" @click="updateProfile">
                          Speichern
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane"
                    v-bind:class="{ active: selectedTab === 'SETTINGS' }"
                  >
                    TO-DO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data: function() {
    return {
      selectedTab: "INFO",
      form: new window.Form({
          id: "",
          first_name: "",
          last_name: "",
          username: "",
          password: "",
          password_confirmation: "",
      }),
      changePassword: false,
    };
  },

  methods:{
    updateProfile(){
      this.form
          .post("/profile")
          .then(() => {
              this.$auth.fetch();
              this.$swal({
                    icon: "success",
                    title: "Änderungen gespeichert",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
              });
              this.loadProfile();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
          });
    },

    async loadProfile() {
            this.$Progress.start();
            await this.axios
                .get("/profile")
                .then((response) => {
                    this.form.fill(response.data.data);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
  },

  created() {
        this.loadProfile();
    },
};
</script>

<style>
.nav-link.active:hover {
  color: white !important;
}
</style>
